import React from "react";
import RecentProjects from "../components/RecentProjects";
import projectsData from "./projectsData";

function HomePage() {
  return (
    <div className="relative overflow-hidden">
      <div className="pt-48 pb-28 md:pb-[8vw] relative z-10">
        <div className="w-full px-[6vw] xl:px-[12vw] 2xl:px-[16vw] text-center">
          <h1 className="uppercase text-[10vw] xl:text-[8vw] leading-[0.8]">
            Crafting UX <br /> for{" "}
            <span className="text-color-active"> Web3</span>
          </h1>
          <h2 className="mt-12 max-w-[400px] mx-auto text-lg leading-tight">
            I am a product designer focused on fintech and Web3. I am also the
            co-founder of Blaze, the first payment app for modern nomads.
          </h2>
        </div>
      </div>
      <div className="relative z-10">
        <RecentProjects projectsData={projectsData} />
      </div>
    </div>
  );
}

export default HomePage;
